import { Container, Text, Flex } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

import { s3ImageURL } from '@miimosa/common'
import type { FrontCollectTypeType } from '@miimosa/common/types'

const Step = ({ index, title, subtitle }: { index: number; title: string; subtitle: string }) => (
  <Flex direction="column" align="center" width={{ base: '100%', md: '260px' }}>
    <Flex
      borderRadius="38px"
      width="38px"
      height="38px"
      align="center"
      justify="center"
      border="solid 1px"
      borderColor="yellow"
    >
      <Text fontSize="sm" color="yellow">
        {index + 1}
      </Text>
    </Flex>
    <Text fontSize="xl" mt="4" width={{ base: 'auto', md: '180px' }} textAlign="center" color="white">
      {title}
    </Text>
    <Text fontSize="md" mt="4" textAlign="center" color="white">
      {subtitle}
    </Text>
  </Flex>
)

const Steps = ({ collectType = 'donation' }: { collectType: FrontCollectTypeType }) => {
  const { t } = useTranslation('projects', { keyPrefix: 'steps' })

  return (
    <Container variant="full" bgImage={s3ImageURL('background-texture-green.jpg')} bgRepeat="repeat">
      <Container variant="boxed">
        <Flex
          columnGap={6}
          rowGap={{ base: 6, md: 0 }}
          width="100%"
          justify={collectType === 'lending' ? 'space-between' : 'space-around'}
          direction={{ base: 'column', md: 'row' }}
          align="flex-start"
        >
          {Array.from(Array(4)).map((_, index) => (
            <Step
              key={index}
              index={index}
              title={t(`${index}.${collectType}.title`)}
              subtitle={t(`${index}.${collectType}.subtitle`)}
            />
          ))}
        </Flex>
      </Container>
    </Container>
  )
}

export default Steps
