import { Container, useBreakpointValue } from '@chakra-ui/react'
import { captureException } from '@sentry/nextjs'
import { isEmpty } from 'lodash'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import { GetServerSideProps, GetServerSidePropsContext } from 'next/types'
import { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'

import { FunnelAPI, ProjectsAPI, extractUserID } from '@miimosa/api'
import { getSubscription } from '@miimosa/api/v2/routes'
import { v3toFrontProject, v3toFrontSearchProject, extractString, buildV2ImagePath } from '@miimosa/common'
import type { FrontProjectType, FrontSearchProjectType, Lang, Subscription } from '@miimosa/common/types'
import { parseLocale } from '@miimosa/design-system/lib/hooks/use_platform'
import { Projects, Search } from '@miimosa/protocol'

import { DonationProjectInterface, NewProjectList, LendingProjectInterface } from '@components'
import { Steps } from '@components/pages/projects'

const GetAsyncProjects = async (params: Search.FeaturedProjectsRequest): Promise<FrontSearchProjectType[]> => {
  const projectsApi = new ProjectsAPI()
  try {
    const results = await projectsApi.getFeaturedProjects(params)
    if (results.kind === 'success') {
      const formattedProjects = results.data.projects.map((project) =>
        v3toFrontSearchProject({ ...project, imageFileSize: 'card' })
      )
      return formattedProjects
    }
    return []
  } catch {
    return []
  }
}

async function fetchProjects(slug: string, locale: Lang, userId?: number): Promise<Projects.FetchProjectResponse> {
  const projectsAPIv3 = new ProjectsAPI()

  try {
    const results = await projectsAPIv3.fetchProject({
      slug: slug,
      user_id: userId,
      locale,
    })

    return (results.kind === 'success' ? results.data : {}) as Projects.FetchProjectResponse
  } catch (err) {
    captureException(err)
    return {} as Projects.FetchProjectResponse
  }
}

const retriveSubscription = async (slug: string, userId: number) => {
  try {
    const { data } = await getSubscription(slug, {
      user_id: userId,
    })
    return data
  } catch (err) {
    captureException(err)
  }
}

const fetchLastFunnel = async (context: GetServerSidePropsContext) => {
  const funnelAPI = new FunnelAPI(context)
  try {
    const results = await funnelAPI.fetchPSFPFunnel({})
    return results.kind === 'success' ? results.data.is_informed : false
  } catch (err) {
    captureException(err)
  }
}

// getServerSideProps need because we need the req to extract userId..
export const getServerSideProps: GetServerSideProps = async (context: GetServerSidePropsContext) => {
  const { lang, platform } = parseLocale(context.locale)
  const slugParams = context.params?.slug
  const slug = extractString(slugParams)
  const userId = await extractUserID(context.req)

  let projectV3 = undefined

  if (slug) projectV3 = await fetchProjects(slug, lang, userId)
  const projects = await GetAsyncProjects({ branch_code: `mii_${platform}`, locale: lang })

  if (isEmpty(projectV3) || !projectV3) {
    return {
      notFound: true,
    }
  }

  let subscriptionState: Subscription | undefined
  if (slug && userId) subscriptionState = await retriveSubscription(slug, userId)

  const formattedProject = v3toFrontProject(projectV3)

  const isInformed = await fetchLastFunnel(context)

  return {
    props: {
      ...(await serverSideTranslations(context.locale || 'fr', ['common', 'projects', 'home', 'merchant', 'project'])),
      slug,
      project: formattedProject,
      projects,
      subscriptionState: subscriptionState?.subscription || null,
      isInformed: isInformed || false,
    },
  }
}

interface Props {
  slug: string
  projects: FrontSearchProjectType[]
  project: FrontProjectType
  subscriptionState: Subscription['subscription']
  isInformed: boolean
}

const Project = ({ projects, project, subscriptionState, isInformed }: Props) => {
  const { t } = useTranslation('project', { keyPrefix: 'collect' })
  const [activeTab] = useState('projects')
  const itemsPerPage = useBreakpointValue({ base: 1, md: 2, lg: 3, xl: 4, '2xl': 5 }, { fallback: 'xl' }) as number

  useEffect(() => {
    let defaultItem = {
      item_category: project.collect.type,
      item_category2: project.category,
      project_page_url: `/projects/${project.slug}`,
      item_name: project.title,
      project_description: project.description.shortDescription,
      project_picture: buildV2ImagePath({
        filename: project.description.imageFileName || '',
        id: project.id,
        kind: 'project',
        size: 'thumb',
      }),
      goal_amount: project.collect.goalAmount,
      project_state: project.state,
      goal_type: project.collect.goalType,
    }
    if (project.collect.type == 'lending') {
      defaultItem = {
        ...defaultItem,
        ...{
          interest_rate: project.collect.interestsRatio,
          duration: `${project.collect.termQuantity} ${t(`lending.period_${project.collect.termFrequency}`)}`,
          goal_type: 'amount',
        },
      }
    }
    if (project.collect.goalType == 'quantity') {
      defaultItem = {
        ...defaultItem,
        ...{ goal_quantity: project.collect.goalQuantity },
      }
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'view_item',
        ecommerce: {
          items: [defaultItem],
        },
      },
    })
  }, [project])

  return (
    <>
      <Head>
        <title>{project.title}</title>
        <meta name="description" content={project.description?.shortDescription} />
        <meta property="og:title" content={project.title} key="title" />
        <meta property="og:description" content={project.description?.shortDescription} key="description" />
        <meta property="og:image" content={project.description?.smallImageFileName} key="image" />
      </Head>
      {project.collect.type == 'donation' ? (
        <DonationProjectInterface project={project} activeTab={activeTab} subscriptionState={subscriptionState} />
      ) : (
        <LendingProjectInterface
          project={project}
          activeTab={activeTab}
          subscriptionState={subscriptionState}
          isInformed={isInformed}
        />
      )}
      <Container variant="full" p={0} bgColor="creamy">
        <Steps collectType={project.collect.type} />
        <NewProjectList projects={projects} itemsPerPage={itemsPerPage} />
      </Container>
    </>
  )
}

export default Project
